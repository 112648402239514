import { MetricsEventType } from '@a0/docs-components/metrics';
import ApplicationStore from '../stores/ApplicationStore';
export function login(returnTo) {
    return function (context) {
        var appStore = context.getStore(ApplicationStore);
        var url = "".concat(appStore.getDomainUrlDocs(), "/auth/login");
        if (returnTo) {
            url += "?returnTo=".concat(returnTo);
        }
        context.trackEvent(MetricsEventType.LoginButtonClick);
        window.location.href = url;
    };
}
export function silentLogin(returnTo) {
    return function (context) {
        var appStore = context.getStore(ApplicationStore);
        var url = "".concat(appStore.getDomainUrlDocs(), "/auth/login/silent");
        if (returnTo) {
            url += "?returnTo=".concat(returnTo);
        }
        context.trackEvent(MetricsEventType.LoginButtonClick);
        window.location.href = url;
    };
}
export function signup(context) {
    var appStore = context.getStore(ApplicationStore);
    var url = appStore.getDomainUrlSignup();
    context.trackEvent('click:auth0-docs:signup');
    window.location = url;
}
